import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-address-column-renderer',
  templateUrl: './address-column-renderer.component.html',
  styleUrls: ['./address-column-renderer.component.scss']
})
export class AddressColumnRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  public data: any;
  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    if (this.data.chargerLocation) {
      this.data.address = this.data.chargerLocation.addressLine1.split(',');
    }
  }
  refresh(): boolean {
    return false;
  }
}
