import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/post-login/dashboard.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public productInfo;

  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.productInfo = this.dashboardService.returnProductInfo();
    if (!this.productInfo) {
      this.dashboardService.getProdInfo('productinfo').subscribe((res: any) => {
        this.dashboardService.setProductInfo(res.productinfo);
        this.productInfo = res.productinfo;
      });
    }
  }

}
