import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { RowNode, IDoesFilterPassParams } from 'ag-grid-community';

let columnName;

@Component({
  selector: 'app-custom-date-filter',
  templateUrl: './custom-date-filter.component.html',
  styleUrls: ['./custom-date-filter.component.scss']
})
export class CustomDateFilterComponent implements OnInit {

  @ViewChild('flatpickrEl', { read: ElementRef, static: false }) flatpickrEl: ElementRef;
  private date: Date[];
  public fDate: Date;
  public tDate: Date;
  private params: any;
  private valueGetter: (rowNode: RowNode) => any;
  private hideFilter: Function;
  private columnName;

  filterHeight;
  filterWidth;

  constructor() { }

  ngOnInit() {
    this.filterHeight = '307px';
    this.filterWidth = '196px';
  }

  agInit(params: any): void {
    this.params = params;
     this.valueGetter = params.valueGetter;
  }

  afterGuiAttached(params) {
    this.columnName = this.params['name'];
    columnName = this.params['name'];
    this.hideFilter = params.hidePopup;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const date = new Date(this.valueGetter(params.node)),
    fDate = this.fDate && new Date(`${this.fDate['year']}-${this.fDate['month']}-${this.fDate['day']}`),
    tDate = this.tDate && new Date(`${this.tDate['year']}-${this.tDate['month']}-${this.tDate['day']}`);
    const year = date.getFullYear(), month = date.getMonth(), day = date.getDate();
    const cDate = new Date(`${year}-${month + 1}-${day}`);
    return this.date ?
    ((this.fDate ? fDate <= date : true) &&
    (this.tDate ? tDate >= cDate : true)) : true;
  }

  getDate(): Date[] {
    return this.date;
  }

  setDate(date: Date[]): void {
    if ( date && date.length) {
       this.date = date || null;
    }
  }

  isFilterActive(): boolean {
    return this.columnName === columnName || this.params.column.isFilterActive();
  }

  onClear() {
    if (this.date) {
      this.date = null;
      this.fDate = null;
      this.tDate = null;
      this.params.api.onFilterChanged();
    }
    this.params.column.setFilterActive(false);
    this.hideFilter();

  }
  onApply() {
    this.date = [];
    this.date[0] = this.fDate;
    this.date[1] = this.tDate;
    this.params.api.onFilterChanged();
    this.hideFilter();
  }

  onCancel() {
    this.hideFilter();
  }

  setHeight() {
this.filterHeight = '453px';
this.filterWidth = '250px';
  }

  setMinHeight() {
    this.filterHeight = '307px';
    this.filterWidth = '197px';
  }

}
