import { Component, OnInit } from '@angular/core';
import { ModalServiceService } from '../../shared/components/side-modal/modal-service.service';
import { DashboardService } from '../../dashboard.service';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { DeleteModalComponent } from '../../shared/components/delete-modal/delete-modal.component';
import { ActionColumnRendererComponent } from '../../shared/components/action-column-renderer/action-column-renderer.component';
import { CustomFilterComponent } from '../../shared/components/custom-filter/custom-filter.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  public gridOptions: any;
  public UMRowData: Array<any> = [];
  public frameworkComponent;
  public user: any = {
    given_name: '',
    username: '',
    role: '',
    company: ''
  };
  private modalRef: MDBModalRef;

  successMessage;
  errorMessage;
  staticAlertClosed = true;

  UMColumnDefs = [
    {
       headerName: '',
       field: '',
       width: 40,
       headerCheckboxSelection: true,
       headerCheckboxSelectionFilteredOnly: true,
       checkboxSelection: true,
       suppressMenu: true,
       cellStyle: {
         borderRight: '1px solid #E5E5E5;'
       },
       sortable: false
     },
     {
       headerName: 'Name',
       field: 'name',
       width: 186,
       filter: 'customFilter',
       filterParams: {
        value: 'name',
        name: 'Name'
        },
       suppressSizeToFit: true,
       getQuickFilterText: function(params) {
         return params.value;
       },
       cellStyle: {
         borderRight: '1px solid #E5E5E5;',
         color: '#212121',
         'font-family': 'Gotham',
         'font-size': '14px;',
         'font-weight': '300;',
         'line-height': '60px;'
       }
     },
     {
       headerName: 'Username',
       field: 'username',
       cellStyle: {
         borderRight: '1px solid #E5E5E5;',
         color: '#212121;',
         'font-family': 'Gotham;',
         'font-size': '14px;',
         'font-weight': '300',
         'line-height': '60px;',
         whiteSpace: 'normal',
         wordWrap: 'break-word'
       },
       filter: 'customFilter',
       filterParams: {
        value: 'username',
        name: 'Username'
        },
     },
     {
       headerName: 'Role',
       field: 'role',
       cellStyle: {
         borderRight: '1px solid #E5E5E5;',
         'color': '#212121;',
         'font-family': 'Gotham;',
         'font-size': '14px;',
         'font-weight': '300;',
         'line-height': '60px;',
         whiteSpace: 'normal',
         wordWrap: 'break-word'
       },
       filter: 'customFilter',
       filterParams: {
        value: 'role',
        name: 'Role'
        },
        getQuickFilterText: () => ''
     },
     {
       headerName: 'Company',
       field: 'company',
       width: 90,
       cellStyle: {
         borderRight: '1px solid #E5E5E5;',
         color: '#212121',
         'font-family': 'Gotham',
         'font-size': '14px;',
         'font-weight': '300;',
         'line-height': '60px;'
       },
       filter: 'customFilter',
       filterParams: {
        value: 'company',
        name: 'Company'
        },
        getQuickFilterText: () => ''
     },
     {
       headerName: 'Actions',
       field: 'actions',
       maxWidth: 69,
       cellRenderer: 'actionColumn',
       cellStyle: {
         textAlign: 'center',
         'line-height': '60px;'
       },
       suppressMenu: true,
       sortable: false,
       cellClass: 'no-border'
     }
  ];

  private modalOptions;
  constructor(
    private modalService: ModalServiceService,
    private dashboardService: DashboardService,
    private deleteModalService: MDBModalService
  ) {
    this.frameworkComponent = {
      actionColumn: ActionColumnRendererComponent,
      customFilter: CustomFilterComponent
    };
  }

  alertType() {
    return this.successMessage ? 'success' : 'danger';
  }

  getSelectedRowsData() {
    if (this.gridOptions) {
      const selectedNodes = this.gridOptions.getSelectedNodes();
      return selectedNodes;
    }
  }


  openDeleteModal() {
    const selected = this.getSelectedRowsData();
    this.modalOptions = {
      class: 'modal-dialog-centered',
      data: {
        selectedData: selected,
        textToDisplay: (selected.length > 1 ? `selected ${selected.length} users` : `"${selected[0].data.username}"`)
      }
    };
    this.modalRef = this.deleteModalService.show(DeleteModalComponent, this.modalOptions);
    this.modalRef.content.action.subscribe((dataToBeDeleted: any) => {
      if (dataToBeDeleted.length > 1) {
        const multipleusers = [];
        dataToBeDeleted.forEach(element => {
          multipleusers.push(element.data.username);
        });
        this.dashboardService.deleteMultipleUsers('users/delete', multipleusers).subscribe((res: any) => {
          this.successMessage = res.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
          this.dashboardService.getUsers('users').subscribe((response: any) => {
            this.UMRowData = response.users;
            this.UMRowData.forEach((user) => {
              user.name = user.given_name + " " + user.family_name;
            });
            this.dashboardService.prepareActionsForUsers(this.UMRowData);
          });
        }, (err: any) => {
          this.errorMessage = err.error.message || err.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
        });
      } else {
        this.dashboardService.deleteSingleUser('users', dataToBeDeleted[0].data.username).subscribe((res: any) => {
          this.successMessage = res.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
          this.dashboardService.getUsers('users').subscribe((response: any) => {
            this.UMRowData = response.users;
            this.UMRowData.forEach((user) => {
              user.name = user.given_name + " " + user.family_name;
            });
            this.dashboardService.prepareActionsForUsers(this.UMRowData);
          });
        }, (err: any) => {
          this.errorMessage = err.error.message || err.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
        });
      }
    });
  }

  ngOnInit() {
    this.dashboardService.getUsers('users').subscribe((data: any) => {
      this.UMRowData = data.users;
      this.UMRowData.forEach((user) => {
        user.name = user.given_name + " " + user.family_name;
      });
      this.dashboardService.prepareActionsForUsers(this.UMRowData);
    });
  }

  getGirdAPI(gridAPI) {
    this.gridOptions = gridAPI;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  getSelectedRows() {
    if (this.gridOptions) {
      const selectedNodes = this.gridOptions.getSelectedNodes();
      if (selectedNodes.length) {
        return false;
      } else {
        return true;
      }
    }
  }

  savePopupData(data) {
    if (data.actions) {
      const dataToBeSent = Object.assign({}, data);
      delete dataToBeSent.actions;
      this.dashboardService.updateUser('users', dataToBeSent).subscribe((res: any) => {
        this.successMessage = res.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
        this.dashboardService.getUsers('users').subscribe((response: any) => {
          this.UMRowData = response.users;
          this.UMRowData.forEach((user) => {
            user.name = user.given_name + " " + user.family_name;
          });
          this.dashboardService.prepareActionsForUsers(this.UMRowData);
        });
      }, (err: any) => {
        this.errorMessage = err.error.message || err.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
      });
    } else {
      this.dashboardService.createNewUser('users', data).subscribe((res: any) => {
        this.successMessage = res.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
        this.user = {
          given_name: '',
          company: '',
          role: '',
          username: ''
        };
        this.dashboardService.getUsers('users').subscribe((response: any) => {
          this.UMRowData = response.users;
          this.UMRowData.forEach((user) => {
            user.name = user.given_name + " " + user.family_name;
          });
          this.dashboardService.prepareActionsForUsers(this.UMRowData);
        });
      }, (err: any) => {
          this.errorMessage = err.error.message || err.message;
          this.staticAlertClosed = false;
          setTimeout(() => this.staticAlertClosed = true, 10000);
      });
    }
  }
}
