import { Component, OnInit, OnChanges } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-date-format',
  templateUrl: './date-format.component.html',
  styleUrls: ['./date-format.component.scss']
})
export class DateFormatComponent implements OnInit, OnChanges, ICellRendererAngularComp {

  private months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  private current_datetime;
  public formatted_date;
  public params: any;
  public data: any;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  agInit(params: any): void {
    this.params = params;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.current_datetime = this.data[params.value] ? new Date(this.data[params.value]) : '';
    if (this.current_datetime) {
      let hours = this.current_datetime.getHours();
      let minutes: any = this.current_datetime.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;     
      this.formatted_date =
        this.current_datetime.getDate() + ' ' + this.months[this.current_datetime.getMonth()] + ' ' + this.current_datetime.getFullYear() + ' ' + hours + ':' + minutes + ' ' + ampm;
    }
  }

  refresh(): boolean {
    return false;
  }
}
