import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DashboardService } from 'src/app/post-login/dashboard.service';

import { _ } from 'underscore';

@Component({
  selector: 'app-log-history-modal',
  templateUrl: './log-history-modal.component.html',
  styleUrls: ['./log-history-modal.component.scss']
})
export class LogHistoryModalComponent implements OnInit {

  @Input() data;

  private history: Array<any> = [];
  public modifiedHistoryArray;
  private for;

  private months =
    ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.for = this.data.actions[0].for;
    this.dashboardService.getHistory(`${this.for}/history`,
      this.for === 'chargers' ? this.data.chargerId : this.data.ruleId).subscribe((data: any) => {
        this.history = this.for === 'chargers' ?
          (data.chargersHistory ? data.chargersHistory : []) :
          (data.ruleHistory ? data.ruleHistory : []);
        if (this.history.length) {
          this.modifyHistoryArray(this.history);
        }
    });
  }

  modifyHistoryArray(data) {
    data.forEach(element => {
      const modifiedDate = element.lastModifiedDate ? new Date(element.lastModifiedDate) : new Date(element.createdDate);
      if (modifiedDate) {
        const monthYear = this.months[modifiedDate.getMonth()] + ' ' + modifiedDate.getFullYear();
        element.monthYear = monthYear;
      }
    });
    this.modifiedHistoryArray = _.groupBy(data, 'monthYear');
  }

  formatDate(date) {
    const setDate = new Date(date);
    let hours = setDate.getHours();
    let minutes: any = setDate.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = setDate.getDate() + ' ' + this.months[setDate.getMonth()] + ' ' + hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

}
