import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { DashboardService } from 'src/app/post-login/dashboard.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-add-edit-view-rule-modal',
  templateUrl: './add-edit-view-rule-modal.component.html',
  styleUrls: ['./add-edit-view-rule-modal.component.scss']
})
export class AddEditViewRuleModalComponent implements OnInit {

  private textToDisplay;
  public selectedData;
  private serversList;
  private allowList;
  private commandList;
  private symbolList;
  private isAddRule: boolean;
  private customersList: any;
  private action: Subject<any> = new Subject();
  private rulesAdditionMethod;
  private rulesAdditionState: Boolean = false;
  private selectedServers: any;
  private getServerIndex;
  private rulesRowData: any = [];

  constructor(
    private modalRef: MDBModalRef,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    if (this.selectedData) {
      this.selectedServers = {
        serverId: []
      };
      this.selectedData.servers.forEach((server) => {
        this.selectedServers.serverId.push(server.serverId);
      });
    }
    if (!this.selectedData) {
      this.isAddRule = true;
      this.selectedServers = {
        serverId: []
      };
    }
    this.dashboardService.getMasterDataForRules('servers').subscribe((response: any) => {
      this.customersList = response.servers;
    });
    this.dashboardService.getMasterDataForRules('policy_names').subscribe((response: any) => {
      this.serversList = response.policyNames;
      if (!this.selectedData) {
        this.selectedData = {
          ruleName: '',
          servers: []
        };
      }
    });
    this.dashboardService.getMasterDataForRules('policy_actions').subscribe((response: any) => {
      this.allowList = response.policyActions;
    });
    this.dashboardService.getMasterDataForRules('partially_allowed_commands').subscribe((response: any) => {
      this.commandList = response.partiallyAllowedCommands;
    });
    this.dashboardService.getMasterDataForRules('partially_allowed_operations').subscribe((response: any) => {
      this.symbolList = response.partiallyAllowedCommands;
    });
  }

  saveData() {
    this.action.next(this.selectedData);
  }

  prepareSelectedServersArray(e) {
    this.selectedServers.serverId.forEach((server) => {
      const findInArray = this.selectedData.servers.find((ele) => {
        return ele.serverId === server;
      });
      if (!findInArray) {
        this.selectedData.servers.push({
          serverId: server,
          serverName: e.serverName,
          policies: []
        });
      }
      const policies = [];
      this.serversList.forEach((policy) => {
        policies.push({
          allow: '',
          policyName: policy
        });
        this.selectedData.servers.forEach((ele: any) => {
          ele.policies = ele.policies.length ? ele.policies : policies;
        });
      });
    });
  }

  onRemoveOfServers(e) {
    const removedServer = e.value.serverId;
    const findInArray = this.selectedData.servers.find((ele) => {
      return ele.serverId === removedServer;
    });
    if (findInArray) {
      this.selectedData.servers.pop({
        serverId: removedServer,
        policies: []
      });
    }
  }

  getSelectedServer(e) {
    const serverIndex = this.selectedData.servers.findIndex((ele, index) => {
      return ele.serverId === e;
    });
    this.getServerIndex = serverIndex;
  }

  checkPoliciesForSelectedServer(index) {
    const servers = this.selectedData.servers[index];
    const findEmpty = servers.policies.find((policy) => {
      return policy.allow === '';
    });
    if (findEmpty) {
      return true;
    } else {
      return false;
    }
  }

  checkPoliciesForAllAddedServers() {
    if (this.selectedData.ruleName === '' || this.selectedData.servers.length === 0) {
      return true;
    } else {
      let findEmpty;
      this.selectedData.servers.forEach(element => {
        findEmpty = element.policies.find((policy) => {
          return policy.allow === '';
        });
      });
      if (findEmpty) {
        return true;
      } else {
        return false;
      }
    }
  }

  ifAllPoliciesSelected(serverId) {
    const serverIndex = this.selectedData.servers.findIndex((ele, index) => {
      return ele.serverId === serverId;
    });
    const servers = this.selectedData.servers[serverIndex];
    const findEmpty = servers.policies.find((policy) => {
      return policy.allow === '';
    });
    if (!findEmpty) {
      return true;
    }
  }

  getRulesIfSelectedCopy() {
    if (this.rulesRowData.length === 0) {
      this.dashboardService.getRulesData('rules').subscribe((data: any) => {
        this.rulesRowData = data.rules ? data.rules : [];
      });
    }
  }

  getSelectedRuleData(e) {
    const findSelectedRule = this.rulesRowData.find((rule) => {
      return rule.ruleId === e;
    });
    if (findSelectedRule) {
      this.selectedData.servers = []; // findSelectedRule.servers;
      const servers = [];
      findSelectedRule.servers.forEach((server, serverIndex) => {
        servers.push({
          serverId: server.serverId,
          serverName: server.serverName,
          policies: []
        });
        if (server.policies.length) {
          server.policies.forEach((policy, policyIndex) => {
            servers[serverIndex].policies.push({
              policyName: policy.policyName,
              allow: policy.allow,
              commands: []
            });
            if (policy.commands.length) {
              policy.commands.forEach((command) => {
                servers[serverIndex].policies[policyIndex].commands.push({
                  commandName: command.commandName,
                  symbol: command.symbol,
                  value: command.value
                });
              });
            }
          });
        }
      });
      this.selectedData.servers = servers;
    }
  }

}
