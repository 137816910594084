import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss'],
})
export class CommonTableComponent implements OnInit, OnChanges {

  @Input()  columnDefs: 'columnDefs';
  @Input()  rowData: 'rowData';
  @Input() headerHeight: 'headerHeight';
  @Input() rowHeight: 'rowHeight';
  @Input() showPagination: 'showPagination';
  @Input() paginationPageSize: 'paginationPageSize';
  @Input() gridHeight: 'gridHeight';
  @Input() setGridHeight;
  @Input() floatingFilter;
  @Input() frameworkComponent;
  @Output() sendGridAPI: EventEmitter<any> = new EventEmitter();
  @Input() showFilter;

  private windowWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
    if(this.windowWidth >= 1300) {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    }
  }

  public defaultColDef: any;
  private gridApi;
  private gridColumnApi;
  private pages: Array<any> = [];
  public rowSelection;
  private filterIcon: any;
  private frameworkComponents;
  config: any;
  constructor(
  ) {
    this.defaultColDef = {
      resizable: false,
      draggable: false,
      filter: this.showFilter,
      sortable: true,
      icons : {
        menu: '<i class="fa fa-filter"/>',
        filter: '<img src="../../../../../assets/images/filetrs-applied.svg">'
      },
    };
    this.rowSelection = 'multiple';
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    this.config = {
      itemsPerPage: this.paginationPageSize,
      currentPage: 1,
      totalItems: this.rowData ? this.rowData.length : this.rowData
    };
    this.windowWidth = window.innerWidth;
  }

  ngOnChanges() {
    this.defaultColDef.filter = this.showFilter;
  }

  getFilterIcon(params) {
    // if (this.gridApi) {
      if ( this.gridApi.isFilterActive() === true) {
        return '<img src="../../../../../assets/images/filetrs-applied.svg">';
      } else {
        return '<i class="fa fa-filter"/>';
      }
    // }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.sendGridAPI.emit(this.gridApi);
    this.defaultColDef.filter = this.showFilter;
  }

  onFirstDataRendered(params) {
    if(this.windowWidth >= 1300) {
      params.api.sizeColumnsToFit();
    }
  }

  paginationChanged() {
    if (this.gridApi) {
      this.gridApi.forEachNode( rowNode => {
    });
      this.pages = [];
      for (let k = 1;
        k <= this.gridApi.paginationGetTotalPages() ;
        k++) {
            this.pages.push(k);
        }
    }
  }

}
