import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Input() height;

  private loggedInUserRole;
  public sideNavArray: Array<any> = [];

  @Output() hover: EventEmitter<any> = new EventEmitter();
  constructor(
    private loginService: LoginServiceService
  ) { }

  ngOnInit() {
    this.loggedInUserRole =
      this.loginService.getLoggedInUserDetails() ?
      this.loginService.getLoggedInUserDetails()['custom:userRole'] :
        this.loginService.getLoggedInUserDetails();
    this.sideNavArray.push(
      {
        id: 1, name: 'Dashboard', routerLink: 'dashboard', image: 'db'
      },
      {
        id: 2, name: 'Chargers', routerLink: 'chargePoints', image: 'chargepoints'
      },
      {
        id: 3, name: 'Rules', routerLink: 'rules', image: 'rules'
      }
    );
    if (this.loggedInUserRole === 'Admin') {
      this.sideNavArray.push(
        {
          id: 4, name: 'User Management', routerLink: 'userManagement', image: 'users'
        }
      );
    }
  }
}
