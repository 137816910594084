import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {FormsModule} from '@angular/forms';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { HeaderComponent } from './components/header/header.component';
import { CommonTableComponent } from './components/common-table/common-table.component';
import {AgGridModule} from 'ag-grid-angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { ModalModule, WavesModule, PopoverModule, CollapseModule, CheckboxModule, ButtonsModule, InputsModule, InputUtilitiesModule } from 'angular-bootstrap-md';
import { SearchComponent } from './components/search/search.component';
import { ActionColumnRendererComponent } from './components/action-column-renderer/action-column-renderer.component';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { FooterComponent } from './components/footer/footer.component';
import { DateFormatComponent } from './components/date-format/date-format.component';
import { ResetPasswordModalTemplateComponent } from '../components/user-profile/reset-password-modal-template/reset-password-modal-template.component';
import { ModalDirective } from './components/side-modal/modal-directive';
import { AddUserPopupBodyComponent } from '../components/user-management/add-user-popup-body/add-user-popup-body.component';
import { AssignRulesPopupComponent } from '../components/charge-points/assign-rules-popup/assign-rules-popup.component';
import { LogHistoryModalComponent } from './components/log-history-modal/log-history-modal.component';
import { AddRulesPopupComponent } from '../components/rules/add-rules-popup/add-rules-popup.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { AddEditViewRuleModalComponent } from './components/add-edit-view-rule-modal/add-edit-view-rule-modal.component';
import { ChargerNameColumnRendererComponent } from './components/charger-name-column-renderer/charger-name-column-renderer.component';
import { RuleNameColumnRendererComponent } from './components/rule-name-column-renderer/rule-name-column-renderer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';
import { CustomFilterComponent } from './components/custom-filter/custom-filter.component';
import { AddressColumnRendererComponent } from './components/address-column-renderer/address-column-renderer.component';
import { ChargersConnectedColumnRendererComponent } from './components/chargers-connected-column-renderer/chargers-connected-column-renderer.component';
import { CustomDateFilterComponent } from './components/custom-date-filter/custom-date-filter.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    SideNavComponent,
    HeaderComponent,
    CommonTableComponent,
    PaginationComponent,
    SideModalComponent,
    SearchComponent,
    ActionColumnRendererComponent,
    FooterComponent,
    DateFormatComponent,
    ModalDirective,
    LogHistoryModalComponent,
    DeleteModalComponent,
    AddEditViewRuleModalComponent,
    AddRulesPopupComponent,
    ChargerNameColumnRendererComponent,
    RuleNameColumnRendererComponent,
    CustomFilterComponent,
    AddressColumnRendererComponent,
    ChargersConnectedColumnRendererComponent,
    CustomDateFilterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AgGridModule.withComponents([]),
    NgxPaginationModule,
    ModalModule,
    PopoverModule,
    ChartsModule,
    WavesModule,
    FormsModule,
    CollapseModule,
    ShowHidePasswordModule,
    NgSelectModule,
    CheckboxModule,
    InputsModule,
    InputUtilitiesModule,
    NgbModule
  ],
  entryComponents: [
    ActionColumnRendererComponent,
    AddressColumnRendererComponent,
    CustomFilterComponent,
    CustomDateFilterComponent,
    DateFormatComponent,
    ResetPasswordModalTemplateComponent,
    AddUserPopupBodyComponent,
    AssignRulesPopupComponent,
    LogHistoryModalComponent,
    AddRulesPopupComponent,
    DeleteModalComponent,
    AddEditViewRuleModalComponent,
    ChargerNameColumnRendererComponent,
    RuleNameColumnRendererComponent,
    ChargersConnectedColumnRendererComponent
  ],
  exports: [
    SideNavComponent,
    HeaderComponent,
    CommonTableComponent,
    PaginationComponent,
    SideModalComponent,
    SearchComponent,
    ActionColumnRendererComponent,
    FooterComponent,
    DateFormatComponent,
    AddRulesPopupComponent
  ]
})
export class SharedModule { }
