import { Component, OnInit } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams, RowNode } from 'ag-grid-community';

let columnName;

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent implements OnInit, IFilterAngularComp {

  public params: IFilterParams;
  private passParams: IDoesFilterPassParams;
  private valueGetter: (rowNode: RowNode) => any;
  public filterValues: Array<any> = [];
  private statusList: Array<any> = [];
  private hideFilter: Function;
  private columnName;

  allChecked = true;
  text: any[] = [];
  status: any[] = [];
  constructor() { }
  originalFIlterValues;

  ngOnInit() {
    this.onAllChecked();
  }

  agInit(params: IFilterParams): void {
    this.params = params;
    this.valueGetter = params.valueGetter;
    this.params.api.forEachNode(rowNode => {
      const findInArray = this.filterValues.find((ele) => {
        return ele.name === rowNode.data[params['value']];
      });
      if (!findInArray) {
        this.filterValues.push(
          {
            name: rowNode.data[params['value']],
            checked: false,
            columnName: this.params['name']
          }
        );
      }
      this.originalFIlterValues = Array.apply([], this.filterValues);
    });
    this.statusList.push(
      {
        name: 'Online',
        value: ['online', 'Idle', 'Charging'],
        statusChecked: true
      },
      {
        name: 'Offline',
        value: ['Unavailable', 'offline'],
        statusChecked: true
      },
      {
        name: 'Error',
        value: ['error', 'Faulted', 'Multiplexer Faulted'],
        statusChecked: true
      }
    );
  }

  afterGuiAttached(params) {
    this.columnName = this.params['name'];
    columnName = this.params['name'];
    this.hideFilter = params.hidePopup;
  }

  isFilterActive(): boolean {
    return this.columnName === columnName || this.params.column.isFilterActive();
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    this.passParams = params;
    return this.text
      .some((filterWord) => {
        return ((this.params['isStatus'] && this.status) ? this.status.includes(params.data.status) : true)  && ((this.valueGetter(params.node) && filterWord) ? this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0 : 
        this.valueGetter(params.node) === filterWord);
      });
  }

  getModel(): any {
    return { value: this.text };
  }

  setModel(model: any): void {
    this.text = model ? model.value : [];
  }

  // noinspection JSMethodCanBeStatic
  componentMethod(message: string): void {
    alert(`Alert from PartialMatchFilterComponent ${message}`);
  }

  onApply(): void {
    this.status = [];
    if (!this.allChecked || this.text.length !== this.filterValues.length) {
      this.text = [];
      this.filterValues.forEach((ele, index) => {
        if (ele.checked) {
          this.text.push(ele.name && ele.name.toLowerCase());
        }
      });
      this.statusList.forEach( (ele) => {
        if (this.params['isStatus'] && ele.statusChecked) {
          this.status.push(...ele.value);
        }
      });
      this.params.filterChangedCallback();
    } else {
      this.statusList.forEach( (ele) => {
        if (this.params['isStatus']  && ele.statusChecked) {
          this.status.push(...ele.value);
        }
      });
      this.params.filterChangedCallback();
      if (!this.params['isStatus'] ||  this.status.length === 8) {
        this.params.column.setFilterActive(false);
      }
    }
    this.hideFilter();


  }

  isAllchecked() {
    if (this.filterValues.find(ele => !ele.checked)) {
      this.allChecked = false;
    } else {
      this.allChecked = true;
    }
  }

  onAllChecked() {
    this.text = [];
    this.filterValues.map(ele => {
      ele.checked = this.allChecked;
      if (ele.checked) {
        if(typeof ele.name === 'object') {
          this.text.push(ele.name && ele.name[1] && ele.name[1].toLowerCase());
        } else  {
          this.text.push(ele.name && ele.name.toLowerCase());
        }
      }
      return ele;
    });
  }

  searchFilteredArray(filterText) {
    this.filterValues = this.originalFIlterValues.filter((filter: any) => {
      return (filter.name) &&(filter.name).toLowerCase().includes(filterText.toLowerCase());
    });
  }

  onClear() {
    if (!this.allChecked) {
      this.allChecked = true;
      this.onAllChecked();
      this.status = null;
      this.statusList = this.statusList.map( ele =>  { 
        ele.statusChecked = true; 
        return ele;});
      this.params.filterChangedCallback();
    } else if (this.params['isStatus']) {
      this.status = null;
      this.statusList = this.statusList.map( ele => {
        ele.statusChecked = true;
        return ele; });
      this.params.filterChangedCallback();
    }
    this.params.column.setFilterActive(false);
    this.hideFilter();

  }

  onCancel() {
    this.hideFilter();
  }

}