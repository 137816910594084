import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ClassName } from 'angular-bootstrap-md/lib/free/modals/modal.options';

@Component({
  selector: 'app-charger-name-column-renderer',
  templateUrl: './charger-name-column-renderer.component.html',
  styleUrls: ['./charger-name-column-renderer.component.scss']
})
export class ChargerNameColumnRendererComponent implements OnInit, ICellRendererAngularComp {

  public params: any;
  public data: any;
  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
  }
  refresh(): boolean {
    return false;
  }

  getStatusClass(classNames) {
    return classNames;
  }

}
