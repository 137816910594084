import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() placeHolder;
  @Input() gridOptions;
  @Input() filter;
  @Output() searchFilter: EventEmitter<string> = new EventEmitter<string>();

  public searchText;

  constructor() { }

  ngOnInit() {
  }

  searchInGrid(searchText) {
    if (this.gridOptions) {
      this.gridOptions.setQuickFilter(searchText);
    } if(this.filter) {
      this.searchFilter.emit(searchText);
    }
  }
}
