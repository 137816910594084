import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ModalServiceService } from '../side-modal/modal-service.service';
import { MDBModalRef } from 'angular-bootstrap-md';
import { AddEditViewRuleModalComponent } from '../add-edit-view-rule-modal/add-edit-view-rule-modal.component';

@Component({
  selector: 'app-action-column-renderer',
  templateUrl: './action-column-renderer.component.html',
  styleUrls: ['./action-column-renderer.component.scss']
})
export class ActionColumnRendererComponent implements OnInit, ICellRendererAngularComp {

  public params: any;
  public data: any;
  public listArray: Array<any> = [];
  private modalOptions;
  private modalRef: MDBModalRef;

  constructor(
    private modalService: ModalServiceService,
  ) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
  }
  refresh(): boolean {
    return false;
  }

  onShown() {
    this.data.isOpened = true;
  }

  onHidden() {
    this.data.isOpened = false;
  }

  openModal(id: string) {
    if (id.includes('editRulePopup') || id.includes('viewRulePopup')) {
      this.params.context.openEditRuleModal(id, this.data);
    } else {
      this.modalService.open(id);
    }
  }

}
