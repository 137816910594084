import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { DateFormatComponent } from '../../shared/components/date-format/date-format.component';
import { MDBModalService, MDBModalRef } from 'angular-bootstrap-md';
import { DeleteModalComponent } from '../../shared/components/delete-modal/delete-modal.component';
import { ModalServiceService } from '../../shared/components/side-modal/modal-service.service';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { AddEditViewRuleModalComponent } from '../../shared/components/add-edit-view-rule-modal/add-edit-view-rule-modal.component';
import { ActionColumnRendererComponent } from '../../shared/components/action-column-renderer/action-column-renderer.component';
import { ChargersConnectedColumnRendererComponent } from '../../shared/components/chargers-connected-column-renderer/chargers-connected-column-renderer.component';
import { CustomFilterComponent } from '../../shared/components/custom-filter/custom-filter.component';
import { CustomDateFilterComponent } from '../../shared/components/custom-date-filter/custom-date-filter.component';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {
  public gridOptions: any;
  public rulesRowData;
  private modalOptions;
  private deleteModalRef: MDBModalRef;
  private addRuleModalRef: MDBModalRef;
  public loggedInUserRole;
  private cellStylesObj;
  public frameworkComponent;

  public rulesColumnDefs;

  successMessage;
  errorMessage;
  staticAlertClosed = true;

  constructor(
    private dahboardService: DashboardService,
    private mdbModalService: MDBModalService,
    private modalService: ModalServiceService,
    private loginService: LoginServiceService
  ) {
    this.cellStylesObj = {
      borderRight: '1px solid #E5E5E5;',
      color: '#212121',
      'font-family': 'Gotham',
      'font-size': '14px;',
      'font-weight': '300;',
      'line-height': '60px;'
    };
    this.rulesColumnDefs = [
      {
        headerName: 'Rule Name',
        field: 'ruleName',
        width: 186,
        filter: 'customFilter',
        filterParams: {
          value: 'ruleName',
          name: 'Rule Names'
        },
        suppressSizeToFit: true,
        getQuickFilterText: function(params) {
          return params.value;
        },
        cellStyle: this.cellStylesObj
      },
      {
        headerName: '# of Charges Connected',
        field: 'chargersCount',
        width: 120,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;'
        },
        cellRenderer: 'chargersConnectedColumn',
        suppressMenu: true,
        getQuickFilterText: () => '',
        cellClass: 'no-border'
      },
      {
        headerName: 'Created Date',
        field: 'createdDate',
        width: 199,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121;',
          'font-family': 'Gotham',
          'font-size': '14px;',
          'font-weight': '300;',
          'line-height': '60px;',
          whiteSpace: 'normal',
          wordWrap: 'break-word'
        },
        getQuickFilterText: () => '',
        cellRendererFramework: DateFormatComponent,
        cellRendererParams: {
          value: 'createdDate'
        },
        filter: 'customDateFilter',
      },
      {
        headerName: 'Created By',
        field: 'createdBy',
        width: 90,
        cellStyle: this.cellStylesObj,
        filter: 'customFilter',
        filterParams: {
          value: 'createdBy',
          name: 'Created By'
        },
        getQuickFilterText: () => ''
      },
      {
        headerName: 'Modified Date',
        field: 'lastModifiedDate',
        width: 130,
        cellStyle: this.cellStylesObj,
        cellRendererFramework: DateFormatComponent,
        cellRendererParams: {
          value: 'lastModifiedDate'
        },
        filter: 'customDateFilter',
        getQuickFilterText: () => ''
      },
      {
        headerName: 'Modified By',
        field: 'lastModifiedBy',
        width: 124,
        filter: 'customFilter',
        filterParams: {
          value: 'lastModifiedBy',
          name: 'Modified By'
        },
        cellStyle: this.cellStylesObj,
        getQuickFilterText: () => ''
      },
      {
        headerName: 'Actions',
        field: 'actions',
        maxWidth: 69,
        cellRenderer: 'actionColumn',
        cellRendererParams: {
          value: 'ruleActions',
          context: this
        },
        cellStyle: {
          textAlign: 'center',
          'line-height': '60px;'
        },
        suppressMenu: true,
        sortable: false,
        cellClass: 'no-border'
      }
    ];
  }

  ngOnInit() {
    this.frameworkComponent = {
      actionColumn: ActionColumnRendererComponent,
      chargersConnectedColumn: ChargersConnectedColumnRendererComponent,
      customFilter: CustomFilterComponent,
      customDateFilter: CustomDateFilterComponent
    };
    this.loggedInUserRole =
      this.loginService.getLoggedInUserDetails() ?
      this.loginService.getLoggedInUserDetails()['custom:userRole'] :
        this.loginService.getLoggedInUserDetails();
    if (this.loggedInUserRole === 'Admin') {
      this.rulesColumnDefs.unshift({
        headerName: '',
        field: '',
        width: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        suppressMenu: true,
        sortable: false,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;'
        }
      });
    }
    this.dahboardService.getRulesData('rules').subscribe((data: any) => {
      this.rulesRowData = data.rules ? data.rules : [];
      this.dahboardService.prepareActionsForRules(this.rulesRowData, this.loggedInUserRole);
    });
  }

  alertType() {
    return this.successMessage ? 'success' : 'danger';
  }

  getGirdAPI(gridAPI) {
    this.gridOptions = gridAPI;
  }

  getSelectedRows() {
    if (this.gridOptions) {
      const selectedNodes = this.gridOptions.getSelectedNodes();
      if (selectedNodes.length) {
        return false;
      } else {
        return true;
      }
    }
  }

  getSelectedRowsData() {
    if (this.gridOptions) {
      const selectedNodes = this.gridOptions.getSelectedNodes();
      return selectedNodes;
    }
  }

  openDeleteModal() {
    const selected = this.getSelectedRowsData();
    this.modalOptions = {
      class: 'modal-dialog-centered',
      data: {
        selectedData: selected,
        textToDisplay:
          selected.length > 1
            ? `selected ${selected.length} rules`
            : `"${selected[0].data.ruleName}"`
      }
    };
    this.deleteModalRef = this.mdbModalService.show(
      DeleteModalComponent,
      this.modalOptions
    );
    this.deleteModalRef.content.action.subscribe((dataToBeDeleted: any) => {
      if (dataToBeDeleted.length > 1) {
        const multipleRules = [];
        dataToBeDeleted.forEach(element => {
          multipleRules.push(element.data.ruleId);
        });
        this.dahboardService
          .deleteMultipleRules('rules/delete', multipleRules)
          .subscribe((res: any) => {
            this.successMessage = res.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
            this.dahboardService
              .getRulesData('rules')
              .subscribe((rules: any) => {
                this.rulesRowData = rules.rules ? rules.rules : [];
                  this.dahboardService.prepareActionsForRules(
                    this.rulesRowData, this.loggedInUserRole
                  );
              });
          }, (err: any) => {
            this.errorMessage = err.error.message || err.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
          });
      } else {
        this.dahboardService
          .deleteSingleRule('rules/delete', dataToBeDeleted[0].data.ruleId)
          .subscribe((res: any) => {
            this.successMessage = res.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
            this.dahboardService
              .getRulesData('rules')
              .subscribe((rules: any) => {
                this.rulesRowData = rules.rules ? rules.rules : [];
                  this.dahboardService.prepareActionsForRules(
                    this.rulesRowData, this.loggedInUserRole
                  );
              });
          }, (err: any) => {
            this.errorMessage = err.error.message || err.message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
          });
      }
    });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  openAddRuleModal() {
    this.modalOptions = {
      class: 'modal-xl',
      data: {
        textToDisplay: ''
      }
    };
    this.addRuleModalRef = this.mdbModalService.show(
      AddEditViewRuleModalComponent,
      this.modalOptions
    );
    this.addRuleModalRef.content.action.subscribe((dataToBeUpdated) => {
      this.dahboardService
        .addNewRule('rules', dataToBeUpdated)
        .subscribe((res: any) => {
          this.successMessage = res.message;
          this.staticAlertClosed = false;
          setTimeout(() => this.staticAlertClosed = true, 10000);
          this.addRuleModalRef.hide();
          this.dahboardService
            .getRulesData('rules')
            .subscribe((rules: any) => {
              this.rulesRowData = rules.rules ? rules.rules : [];
                this.dahboardService.prepareActionsForRules(
                  this.rulesRowData, this.loggedInUserRole
                );
            });
        }, (err: any) => {
          this.errorMessage = err.error.message || err.message;
          this.staticAlertClosed = false;
          setTimeout(() => this.staticAlertClosed = true, 10000);
        });
    });
  }

  openEditRuleModal(id, data) {
    const that = this;
    this.modalOptions = {
      class: 'modal-xl',
      data: {
        selectedData: data,
        textToDisplay: id.includes('viewRulePopup') ? 'View Rule' : ''
      }
    };
    this.addRuleModalRef = this.mdbModalService.show(
      AddEditViewRuleModalComponent,
      this.modalOptions
    );
    this.addRuleModalRef.content.action.subscribe((dataToBeUpdated) => {
      const dataToBeSent = Object.assign({}, dataToBeUpdated);
      delete dataToBeSent.actions;
      this.dahboardService
        .updateRule('rules', dataToBeSent)
        .subscribe((res: any) => {
          this.successMessage = res.message;
          this.staticAlertClosed = false;
          setTimeout(() => this.staticAlertClosed = true, 10000);
          this.addRuleModalRef.hide();
          this.dahboardService
            .getRulesData('rules')
            .subscribe((rules: any) => {
              this.rulesRowData = rules.rules ? rules.rules : [];
                this.dahboardService.prepareActionsForRules(
                  this.rulesRowData, this.loggedInUserRole
                );
            });
        }, (err: any) => {
          this.errorMessage = err.error.message || err.message;
          this.staticAlertClosed = false;
          setTimeout(() => this.staticAlertClosed = true, 10000);
        });
    });
  }
}
