import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/post-login/dashboard.service';

@Component({
  selector: 'app-assign-rules-popup',
  templateUrl: './assign-rules-popup.component.html',
  styleUrls: ['./assign-rules-popup.component.scss']
})
export class AssignRulesPopupComponent implements OnInit {

  @Input() data;
  originalRulesData;

  public rulesRowData: any = [];
  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    const currentrule = this.data.length === 1 ? this.data[0].ruleId : this.data.ruleId;
    if (this.rulesRowData.length === 0) {
      this.dashboardService.getRulesData('rules').subscribe((data: any) => {
        this.rulesRowData = data.rules ? data.rules : [];
      this.originalRulesData = Array.apply([], this.rulesRowData);
        this.rulesRowData.forEach(element => {
          if (element.ruleId === currentrule) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        });
      });
    }
  }

  updateRuleForCharger(rule) {
    if (this.data.length) {
      this.data.forEach(element => {
        element.ruleId = rule.ruleId;
        element.ruleName = rule.ruleName;
      });
    } else {
      this.data.ruleId = rule.ruleId;
      this.data.ruleName = rule.ruleName;
    }
  }

  updateRuleList(rule) {
    this.rulesRowData.forEach((element: any) => {
      if (element === rule) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
  }

  searchFilteredArray(filterText) {
    this.rulesRowData = this.originalRulesData.filter((filter: any) => {
      return (filter.ruleName) &&(filter.ruleName).toLowerCase().includes(filterText.toLowerCase());
    });
  }

}
