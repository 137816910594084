import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as environment from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private commonUrl: any = environment.environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  public getAPI(url) {
    return this.http.get(this.commonUrl + url);
  }

  public postAPI(url, data) {
    return this.http.post(this.commonUrl + url, data);
  }

  public putAPI(url, data) {
    return this.http.put(this.commonUrl + url, data);
  }

  public deleteAPI(url) {
    return this.http.delete(this.commonUrl + url);
  }

}
