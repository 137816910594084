import { Component, OnInit ,AfterViewInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { LoginServiceService } from './login.service';
import { AuthService } from 'src/app/auth/auth.service';
import { MDBModalService, MDBModalRef } from 'angular-bootstrap-md';
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from '../../auth/cognito.service';
import { distinctUntilChanged, map, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,AfterViewInit {

  loginForm = {
    username: '',
    userpd: '',
    rememberMe: false
  };
  users;
  userNames: Array<any> = [];
  public loginErrorMessage: any;
  errorMessage: string;
  successMessage: string;
  mfaStep = false;
  mfaData = {
    destination: '',
    callback: null
  };

  constructor(
    private router: Router,
    private loginService: LoginServiceService,
    private authService: AuthService,
    private currentRoute: ActivatedRoute,
    //private loginModelref:MDBModalRef,
    private mDBModalService:MDBModalService
  ) { }

  ngOnInit() {
    this.successMessage = this.currentRoute.snapshot.queryParams['successMessage'];
    this.errorMessage = this.currentRoute.snapshot.queryParams['errorMessage'];
    this.authService.isAuthenticated(this);
    const users = JSON.parse(localStorage.getItem("rememberedUsers") || "[]"); 
    this.users = Array.apply([], users);
    if(users.length)  {
      users.forEach((user) => {
        this.userNames.push(user.username);
      });
      this.loginForm = Object.assign({}, this.users[0]);
    }

  }
  ngAfterViewInit(){
    this.mDBModalService.hide(1);
    
  }
  
  navigateToFPPage() {
    this.router.navigate(['/forgotPassword']);
  }

  navigateToDashboard() {
    this.authService.authenticate(this.loginForm.username, this.loginForm.userpd, this);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : (this.userNames).filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
    onselect(e) {
      const find= this.users.find((user) => {
        return user.username === e.item
      });
      this.loginForm = find ? Object.assign({}, find) : {username: '', userpd: '', rememberMe: false};
    }

  cognitoCallback(message: string, result: any) {
    if(this.loginForm.rememberMe === true) {
      const users = JSON.parse(localStorage.getItem("rememberedUsers") || "[]");
      let findUser = users.findIndex((user) => {
        return user.username === this.loginForm.username
      });
      
      if(findUser < 0) {
        users.push(this.loginForm);
      } else if(findUser >= 0) {
        users[findUser] = this.loginForm;
      }
      localStorage.setItem('rememberedUsers', JSON.stringify(users));
    }
    if (message !== null) { // error
      this.errorMessage = message;
    } else { // success
      const idToken = result.idToken.jwtToken;
      this.loginService.setAccessToken(idToken);
      this.loginService.setLoggedInUserDetails(result.idToken.payload);
      this.router.navigate(['../user']);
    }
  }

  handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
    this.mfaStep = true;
    this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
    this.mfaData.callback = (code: string) => {
      if (code === null || code.length === 0) {
        this.errorMessage = 'Code is required';
        return;
      }
      this.errorMessage = null;
      callback(code);
    };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.router.navigate(['../user'], {queryParams: {message: 'User is already authenticated'}});
    }
  }

  cancelMFA(): boolean {
    this.mfaStep = false;
    return false;   // necessary to prevent href navigation
  }

  showPassword(img) {
    const image = document.createElement('img');
    const src = '../../../assets/images/passwords/' + img + '.svg';
    image.src = src;
    return image.outerHTML;
  }

}
