export const environment = {
  production: true,
  apiUrl: 'https://api.getcamber.com/prod-cp/proterra/controlpanel/v1/',
  region: 'us-east-1',
  identityPoolId: '',
  userPoolId: 'us-east-1_vpLY1DGxk',
  clientId: '4glsdc6490i9ovvt761dmsivaf',
  cognito_idp_endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_vpLY1DGxk',
  cognito_identity_endpoint: '',
  sts_endpoint: ''
};