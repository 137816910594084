import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpErrorResponse } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { LoginServiceService } from '../pre-login/login/login.service';
import {Router} from '@angular/router';
import { AuthService } from './auth.service';



@Injectable()
export class AuthenticateInterceptorService implements HttpInterceptor {
  constructor(
    private loginService: LoginServiceService,
    private authService: AuthService,
    private router: Router
    
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentToken = this.loginService.getAccessToken();
    

    if (currentToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `${currentToken}`
        }
      });
    }

    return next.handle(request)
           .pipe(
                 catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    if (error.error instanceof ErrorEvent) {
                      if (error.status !== 401) {
                        return;
                       }
                       this.authService.logout();
                       this.loginService.logout();
                       this.router.navigate(['/login'], { queryParams: { errorMessage: 'Session Expired'}});
                      
                    } else {
                      if(currentToken){
                        const expiry = (JSON.parse(atob(currentToken.split('.')[1]))).exp;
                        if((Math.floor((new Date).getTime() / 1000)) >= expiry){
                          this.authService.logout();
                          this.loginService.logout();
                          this.router.navigate(['/login'], { queryParams: { errorMessage: 'Session Expired'}});
                        }
                      }
                    }
                    return throwError(errorMsg);
                 })
           );
  }
}