import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-reset-password-modal-template',
  templateUrl: './reset-password-modal-template.component.html',
  styleUrls: ['./reset-password-modal-template.component.scss']
})
export class ResetPasswordModalTemplateComponent implements OnInit {

  @Input() data;
  constructor() {
  }

  ngOnInit() {
  }

  showPassword(img) {
    const image = document.createElement('img');
    const src = '../../../../../assets/images/passwords/' + img + '.svg';
    image.src = src;
    return image.outerHTML;
  }

}
