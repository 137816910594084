import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() gridApi;
  @Input() rowData;
  @Input() pages;
  constructor() { }

  ngOnInit() {
  }

  start() {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const totalPages = this.gridApi.paginationGetTotalPages();
      if (this.pages.length > 5) {
        if (currentPage <= 2) {
          return 0;
        } else if (currentPage === totalPages - 1) {
          return totalPages - 4;
        } else if (currentPage + 2 === totalPages) {
          return currentPage - 2;
        } else if (currentPage > 2 && (currentPage + 2 < totalPages)) {
          return currentPage - 2;
        }
      } else {
        return 0;
      }
    }
  }

  end() {
    if (this.gridApi) {
      const currentPage = this.gridApi.paginationGetCurrentPage();
      const totalPages = this.gridApi.paginationGetTotalPages();
      if (this.pages.length > 5) {
        if (currentPage <= 2) {
          return 5;
        } else if (currentPage === totalPages - 1) {
          return totalPages;
        } else if (currentPage + 2 === totalPages) {
          return totalPages;
        } else if (currentPage > 2 && (currentPage + 2 < totalPages)) {
          return currentPage + 3;
        }
      } else {
        return this.pages.length;
      }
    }
  }

  goToPage(pageNumber) {
    if (this.gridApi) {
      this.gridApi.paginationGoToPage(pageNumber - 1);
    }
  }

  goToNextPage() {
    this.gridApi && this.gridApi.paginationGoToNextPage();
  }

  goToPreviousPage() {
    this.gridApi && this.gridApi.paginationGoToPreviousPage();
  }

  disablePrevious() {
    if (this.gridApi) {
      if (this.gridApi.paginationGetCurrentPage() === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  disableNext() {
    if (this.gridApi) {
      if (this.gridApi.paginationGetCurrentPage() === this.gridApi.paginationGetTotalPages() - 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  getActivePage(pageNumber) {
    if (this.gridApi) {
      if (this.gridApi.paginationGetCurrentPage() === pageNumber - 1) {
        return true;
      } else {
        return false;
      }
    }
  }

  getCurrentPageRowCount(first?) {
    if (this.gridApi) {
      const total = this.gridApi.paginationGetPageSize() * (this.gridApi.paginationGetCurrentPage() + 1);
      if (first) {
        return (total - this.gridApi.paginationGetPageSize() + 1);
      }
      return (((this.gridApi.getModel().getRowCount() && total) >= this.gridApi.getModel().getRowCount()) ?
      this.gridApi.getModel().getRowCount() : total);
    }
  }

}
