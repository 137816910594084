import { Injectable } from '@angular/core';
import { Component } from 'ag-grid-community';

import { ModalAddItem } from './modal-addItem';
// tslint:disable-next-line: max-line-length
import { ResetPasswordModalTemplateComponent } from '../../../components/user-profile/reset-password-modal-template/reset-password-modal-template.component';
import { AddUserPopupBodyComponent } from '../../../components/user-management/add-user-popup-body/add-user-popup-body.component';
import { AssignRulesPopupComponent } from 'src/app/post-login/components/charge-points/assign-rules-popup/assign-rules-popup.component';
import { LogHistoryModalComponent } from '../log-history-modal/log-history-modal.component';
import { AddRulesPopupComponent } from 'src/app/post-login/components/rules/add-rules-popup/add-rules-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ModalServiceService {
  private components: Array<any> = [];
  private modals: any[] = [];

  constructor() {
    this.components = [
      {
        componentName: 'ResetPasswordModalTemplateComponent',
        component: new ModalAddItem(ResetPasswordModalTemplateComponent)
      },
      {
        componentName: 'AddUserPopupBodyComponent',
        component: new ModalAddItem(AddUserPopupBodyComponent)
      },
      {
        componentName: 'AssignRulesPopupComponent',
        component: new ModalAddItem(AssignRulesPopupComponent)
      },
      {
        componentName: 'LogHistoryModalComponent',
        component: new ModalAddItem(LogHistoryModalComponent)
      },
    ];
   }

  add(modal: any) {
      this.modals.push(modal);
  }
  remove(id: string) {
      this.modals = this.modals.filter(x => x.id !== id);
  }
  open(id: string) {
      const modal: any = this.modals.filter(x => x.id === id)[0];
      modal.open();
  }
  close(id: string) {
      const modal: any = this.modals.filter(x => x.id === id)[0];
      modal.close();
}

  getComponent(componentName) {
    const k = this.components.find((ele) => {
      return ele.componentName === componentName;
    });
    return k.component;
  }

}
