import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(
  ) { }

  public setAccessToken(data) {
    localStorage.setItem('token', data);
  }

  public getAccessToken() {
    return localStorage.getItem('token');
  }

  public setLoggedInUserDetails(data) {
    localStorage.setItem('loggedInUserDetails', JSON.stringify(data));
  }

  public getLoggedInUserDetails() {
    return localStorage.getItem('loggedInUserDetails') && JSON.parse(localStorage.getItem('loggedInUserDetails'));
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('loggedInUserDetails');
  }
}
