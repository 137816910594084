import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MDBModalRef } from 'angular-bootstrap-md';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @Output() deleteData: EventEmitter<any> = new EventEmitter();

  private action: Subject<any> = new Subject();
  private selectedData;
  public textToDisplay;

  constructor(
    public modalRef: MDBModalRef
  ) { }

  ngOnInit() {
  }

  delete() {
    this.modalRef.hide();
    this.action.next(this.selectedData);
  }

}
