import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginServiceService } from '../login/login.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginComponent } from '../login/login.component';
import { MDBModalService, MDBModalRef } from 'angular-bootstrap-md';

@Component({
  selector: 'app-forgot-password-email-sent',
  templateUrl: './forgot-password-email-sent.component.html',
  styleUrls: ['./forgot-password-email-sent.component.scss']
})
export class ForgotPasswordEmailSentComponent implements OnInit {
  public userData: any = {
    confirmationCode: '',
    newpd: ''
  };

  public errorMessage: any;
  private loginCallBack: LoginComponent;

  constructor(
    private router: Router,
    private loginService: LoginServiceService,
    private currentRoute: ActivatedRoute,
    private authService: AuthService,
    private mDBModalService:MDBModalService,
  ) {
    this.loginCallBack = new LoginComponent(this.router, this.loginService, this.authService, this.currentRoute,this.mDBModalService);
  }

  ngOnInit() {
    this.userData.username = this.currentRoute.snapshot.queryParams['username'];
  }

  resetForgotPasswordForUser() {
    this.authService.confirmNewPassword(this.userData.username, this.userData.confirmationCode, this.userData.newpd, this);
  }

  cognitoCallback(message: string) {
    const that = this;
    if (message !== null) { // error
      this.errorMessage = message;
    } else { // success
      this.authService.authenticate(this.userData.username, this.userData.newpd, this.loginCallBack);
    }
  }

  showPassword(img) {
    const image = document.createElement('img');
    const src = '../../../assets/images/passwords/' + img + '.svg';
    image.src = src;
    return image.outerHTML;
  }
}
