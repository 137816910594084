import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {LoginComponent} from '../app/pre-login/login/login.component';
import {ForgotPasswordComponent} from '../app/pre-login/forgot-password/forgot-password.component';
import {ForgotPasswordEmailSentComponent} from '../app/pre-login/forgot-password-email-sent/forgot-password-email-sent.component';
import {ResetPasswordComponent} from '../app/pre-login/reset-password/reset-password.component';

const routes: Routes = [
  {path: '',  redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'forgotPassword', component: ForgotPasswordComponent},
  {path: 'forgotpasswrodEmailSent', component: ForgotPasswordEmailSentComponent},
  {path: 'resetPassword', component: ResetPasswordComponent},
  {path: 'user', loadChildren: () => import(`./post-login/dashboard.module`).then(m => m.DashboardModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
