import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-chargers-connected-column-renderer',
  templateUrl: './chargers-connected-column-renderer.component.html',
  styleUrls: ['./chargers-connected-column-renderer.component.scss']
})
export class ChargersConnectedColumnRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  public data: any;
  constructor() { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.data.connectedChargerNames = [];
    if (this.data.chargers) {
      const chargerNames = this.data.chargers.split(',');
      chargerNames.forEach(element => {
        const split = element.split('-');
        if (split.length) {
          let chargerName = '';
          const last = split[split.length - 1];
          chargerName = element.replace(`-${last}`, '');
          if (last) {
            this.data.connectedChargerNames.push({
              className: last,
              chargerName: chargerName
            });
          }
        }
      });
    }
  }
  refresh(): boolean {
    return false;
  }

  getStatusClass(classNames) {
    return classNames;
  }

}
