import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public errorMessage;
  userData: any =  {
    username: '',
    userpd: '',
    newpd: ''
  };

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private loginService:LoginServiceService
  ) { }

  ngOnInit() {
    this.userData.username = localStorage.getItem("tempUserId");
    this.userData.userpd = this.activeRouter.snapshot.queryParams['temporaryPassword'];
  }

  resetPasswordForUser() {
    this.authService.newPassword(this.userData.username, this.userData.userpd, this.userData.newpd, this);
  }

  cognitoCallback(message: string, result: any) {
    if (message !== null) { // error
      this.errorMessage = message['message'];
    } else { 
      // success
      // move to the next step dashbord loding
      if(result.idToken){
        localStorage.removeItem("tempUserId");
        const idToken = result.idToken.jwtToken;
        this.loginService.setAccessToken(idToken);
        this.loginService.setLoggedInUserDetails(result.idToken.payload);
        this.router.navigate(['../user']);
      }else{
        this.authService.authenticate(this.userData.username, this.userData.newpd, this);
      }
      //this.router.navigate(['/dashboard'])
    }
  }

  showPassword(img) {
    const image = document.createElement('img');
    const src = '../../../assets/images/passwords/' + img + '.svg';
    image.src = src;
    return image.outerHTML;
  }

}
