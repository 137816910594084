import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginServiceService } from '../pre-login/login/login.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(
    private loginService: LoginServiceService,
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401 && err.error.message === 'Session Expired') {
        this.router.navigate(['/login'], { queryParams: { errorMessage: err.error.message}});
        this.authService.logout();
        this.loginService.logout();
        const error = err.error.message || err.message;        
        return throwError(error);
      }
      return throwError(err);
    }));
  }
}
