import { Component, OnInit } from '@angular/core';
import { ModalServiceService } from '../../shared/components/side-modal/modal-service.service';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

 profileDetails;
  successMessage;
  errorMessage;
  staticAlertClosed = true;

  constructor(
    private modalService: ModalServiceService,
    private loginService: LoginServiceService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.profileDetails = this.loginService.getLoggedInUserDetails();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  savePopupData(data) {
    this.authService.changePassword(data.password, data.newPassword, data.email, this);
  }

  alertType() {
    return this.successMessage ? 'success' : 'danger';
  }

  cognitoCallback(message: string, res) {
    const that = this;
    if(message === "Cannot read property 'message' of null") {
      message = null;
    }
    if (message !== null) { // error      
      this.errorMessage = message;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
    } else { // success
      this.successMessage = (res === null || res === {} || res === undefined || res === '') ? 'Password updated successfully' : res.message || res;
            this.staticAlertClosed = false;
            setTimeout(() => this.staticAlertClosed = true, 10000);
    }
  }
}
