import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { DateFormatComponent } from '../../shared/components/date-format/date-format.component';
import { ActionColumnRendererComponent } from '../../shared/components/action-column-renderer/action-column-renderer.component';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { ChargerNameColumnRendererComponent } from '../../shared/components/charger-name-column-renderer/charger-name-column-renderer.component';
import { RuleNameColumnRendererComponent } from '../../shared/components/rule-name-column-renderer/rule-name-column-renderer.component';
import { AddressColumnRendererComponent } from '../../shared/components/address-column-renderer/address-column-renderer.component';
import { ChargersConnectedColumnRendererComponent } from '../../shared/components/chargers-connected-column-renderer/chargers-connected-column-renderer.component';
import { MDBModalRef, MDBModalService } from 'angular-bootstrap-md';
import { AddEditViewRuleModalComponent } from '../../shared/components/add-edit-view-rule-modal/add-edit-view-rule-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardMainComponent implements OnInit {
  public rulesColumnDefs;
  public rulesRowData: Array<any> = [];
  public chargePointsRowData: Array<any> = [];
  public chargePointsColumnDefs;
  public frameworkComponent;
  public loggedInUserRole;

  private modalOptions;
  private modalRef: MDBModalRef;

  successMessage;
  errorMessage;
  staticAlertClosed = true;

  constructor(
    private dashboardService: DashboardService,
    private loginService: LoginServiceService,
    private mdbModalService: MDBModalService,
    private currentRoute: ActivatedRoute
  ) {
    this.rulesColumnDefs = [
      {
        headerName: 'Rule Name',
        field: 'ruleName',
        width: 158,
        suppressSizeToFit: true,
        cellStyle: {
          borderRight: '1px solid #E5E5E5',
          'color': '#212121',
          'font-family': 'Gotham',
          'font-size': '14px',
          'font-weight': 300,
          'line-height': '50px',
          whiteSpace: 'normal',
          wordWrap: 'break-word'
        }
      },
      {
        headerName: '# of Charges Connected',
        field: 'chargersCount',
        width: 120,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;'
        },
        cellRenderer: 'chargersConnectedColumn',
        cellClass: 'no-border'
      },
      {
        headerName: 'Modified Date',
        field: 'lastModifiedDate',
        width: 134,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          'color': '#212121',
          'font-family': 'Gotham',
          'font-size': '14px',
          'font-weight': 300,
          'line-height': '50px'
        },
        cellRendererFramework: DateFormatComponent,
        cellRendererParams: {
          value: 'lastModifiedDate'
        }
      },
      {
        headerName: 'Modified By', field: 'lastModifiedBy', width: 167,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          'color': '#212121',
          'font-family': 'Gotham',
          'font-size': '14px',
          'font-weight': 300,
          'line-height': '50px'
        }
      }
    ];

    this.chargePointsColumnDefs = [
      {
        headerName: 'Charger Name', field: 'chargerName', width: 226, suppressSizeToFit: true,
        cellRendererFramework: ChargerNameColumnRendererComponent,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121', 'font-family': 'Gotham', 'font-size': '14px;', 'font-weight': '300;', 'line-height': '60px;'
        }
      },
      {
        headerName: 'Customer Name', field: 'customerName', width: 170, minWidth: 50, maxWidth: 190,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121;', 'font-family': 'Gotham;', 'font-size': '14px;', 'font-weight': '300',
          whiteSpace: 'normal', wordWrap: 'break-word', 'line-height': '60px;'
        }
      },
      {
        headerName: 'Servers', field: 'servers', width: 199,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;', textDecoration: 'underline',
          'color': '#109AD6;', 'font-family': 'Gotham;', 'font-size': '14px;', 'font-weight': '300;',
          whiteSpace: 'normal', wordWrap: 'break-word', 'line-height': '60px;'
        }
      },
      {
        headerName: 'Charger ID', field: 'chargerId', width: 90,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121', 'font-family': 'Gotham', 'font-size': '14px;', 'font-weight': '300;', 'line-height': '60px;'
        }
      },
      {
        headerName: 'Rule', field: 'ruleName', width: 130,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121', 'font-family': 'Gotham', 'font-size': '14px;', 'font-weight': '300;', 'line-height': '60px;',
          whiteSpace: 'normal', wordWrap: 'break-word',
        },
        cellRendererFramework: RuleNameColumnRendererComponent
      },
      {
        headerName: 'Charger Type', field: 'chargerType', width: 124,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121', 'font-family': 'Gotham', 'font-size': '14px;', 'font-weight': '300;', 'line-height': '60px;'
        }
      },
      {
        headerName: 'Charger Model', field: 'chargerModel', width: 140,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121', 'font-family': 'Gotham', 'font-size': '14px;', 'font-weight': '300;', 'line-height': '60px;'
        }
      },
      {
        headerName: 'Charger Address', field: 'address', width: 160,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121', 'font-family': 'Gotham', 'font-size': '14px;', 'font-weight': '300;', 'line-height': '60px;'
        },
        cellRenderer: 'addressColumn',
      }
    ];

    this.frameworkComponent = {
      actionColumn: ActionColumnRendererComponent,
      addressColumn: AddressColumnRendererComponent,
      chargersConnectedColumn: ChargersConnectedColumnRendererComponent
    };
  }

  ngOnInit() {
    this.successMessage = this.currentRoute.snapshot.queryParams['message'];
    if(this.successMessage) {
      this.staticAlertClosed = false;
      setTimeout(() => this.staticAlertClosed = true, 10000);
    }
    this.loggedInUserRole = this.loginService.getLoggedInUserDetails()['custom:userRole'];
      this.chargePointsColumnDefs.push(
        {
          headerName: 'Actions',
          field: 'actions',
          maxWidth: 69,
          cellRenderer: 'actionColumn',
          cellRendererParams: {
            value: 'chargerActions',
            context: this
          },
          cellStyle: {
            textAlign: 'center', 'line-height': '60px;'

          },
          cellClass: 'no-border',
          sortable: false
        }
      );
      this.rulesColumnDefs.push(
        {
          headerName: 'Actions',
          field: 'actions',
          maxWidth: 69,
          cellStyle: {
            'color': '#212121',
            'font-family': 'Gotham',
            'font-size': '14px',
            'font-weight': 300,
            'line-height': '50px',
            textAlign: 'center'
          },
          cellClass: 'no-border',
          cellRenderer: 'actionColumn',
          cellRendererParams: {
            value: 'ruleActions',
            context: this
          },
          sortable: false
        }
      );
    this.dashboardService.getChargersData('chargers/recent').subscribe((data: any) => {
      this.chargePointsRowData = data.chargers;
      this.dashboardService.prepareActionsForChargers(this.chargePointsRowData, this.loggedInUserRole);
    });
    this.dashboardService.getRulesData('rules/recent').subscribe((data: any) => {
      this.rulesRowData = data.rules ? data.rules : [];
        this.dashboardService.prepareActionsForRules(this.rulesRowData, this.loggedInUserRole);
    });
  }

  alertType() {
    return this.successMessage ? 'success' : 'danger';
  }

  savePopupData(data) {
    const dataToBeSent = Object.assign({}, data);
    delete dataToBeSent.actions;
    if (data.actions[0].for === 'chargers') {
      this.dashboardService.updateRuleForCharger('chargers', dataToBeSent).subscribe((res: any) => {        
        this.successMessage = res.message;
        this.staticAlertClosed = false;
        setTimeout(() => this.staticAlertClosed = true, 10000);
        this.dashboardService.getChargersData('chargers/recent').subscribe((chargers: any) => {
          this.chargePointsRowData = chargers.chargers;
          this.dashboardService.prepareActionsForChargers(this.chargePointsRowData, this.loggedInUserRole);
        });
        this.dashboardService.getRulesData('rules/recent').subscribe((data: any) => {
          this.rulesRowData = data.rules ? data.rules : [];
            this.dashboardService.prepareActionsForRules(this.rulesRowData, this.loggedInUserRole);
        });
      }, (err: any) => {        
        this.errorMessage = err.error.message || err.message;
        this.staticAlertClosed = false;
        setTimeout(() => this.staticAlertClosed = true, 10000);
      });
    }
  }

  openEditRuleModal(id, data) {
    const that = this;
    this.modalOptions = {
      class: 'modal-xl',
      data: {
        selectedData: data,
        textToDisplay: id.includes('viewRulePopup') ? 'View Rule' : ''
      }
    };
    this.modalRef = this.mdbModalService.show(
      AddEditViewRuleModalComponent,
      this.modalOptions
    );
    this.modalRef.content.action.subscribe((dataToBeUpdated) => {
      const dataToBeSent = Object.assign({}, dataToBeUpdated);
      delete dataToBeSent.actions;
      this.dashboardService
        .updateRule('rules', dataToBeSent)
        .subscribe((res: any) => {          
        this.successMessage = res.message;
        this.staticAlertClosed = false;
        setTimeout(() => this.staticAlertClosed = true, 10000);
          this.modalRef.hide();
          this.dashboardService
            .getRulesData('rules/recent')
            .subscribe((rules: any) => {
              this.rulesRowData = rules.rules ? rules.rules : [];
                this.dashboardService.prepareActionsForRules(
                  this.rulesRowData, this.loggedInUserRole
                );
            });
        }, (err: any) => {          
        this.errorMessage = err.error.message || err.message;
        this.staticAlertClosed = false;
        setTimeout(() => this.staticAlertClosed = true, 10000);
        });
    });
  }
}
