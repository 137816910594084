import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public data: any = {
    username: ''
  };
  errorMessage: any;

  constructor(
    private router: Router,
    private authservice: AuthService
  ) { }

  ngOnInit() {
  }

  navigateToFPEmailSent() {
    this.authservice.forgotPassword(this.data.username, this);
  }

  cognitoCallback(message: string, result: any) {
    if (message === null && result === null) { // error
      this.router.navigate(['/forgotpasswrodEmailSent'], { queryParams: { username: this.data.username } });
    } else { // success
      this.errorMessage = message;
    }
  }

}
