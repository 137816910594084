import { Component, OnInit } from '@angular/core';
import { AWSService } from './auth/aws.service';
import { AuthService } from './auth/auth.service';
import { CognitoService } from './auth/cognito.service';
import {Location} from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Proterra';

  constructor(
    public awsUtil: AWSService, 
    public authService: AuthService, 
    public cognito: CognitoService,
    private location:Location
    ) {
  }

  ngOnInit() {
    this.authService.isAuthenticated(this);
    var pathString= this.location.path();
    if(pathString.includes('/resetPassword?username')){
      var patharray = pathString.replace("/resetPassword?username=","").split("&temporaryPassword=");
      if(patharray[0]){
        localStorage.setItem('tempUserId',patharray[0]);
      }
    }
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    const mythis = this;
    this.cognito.getIdToken({
      callback() {

      },
      callbackWithParam(token: any) {
        // Include the passed-in callback here as well so that it's executed downstream
        mythis.awsUtil.initAwsService(null, isLoggedIn, token);
      }
    });
  }
}
