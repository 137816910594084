import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ModalServiceService } from '../side-modal/modal-service.service';

@Component({
  selector: 'app-rule-name-column-renderer',
  templateUrl: './rule-name-column-renderer.component.html',
  styleUrls: ['./rule-name-column-renderer.component.scss']
})
export class RuleNameColumnRendererComponent implements OnInit, ICellRendererAngularComp {
  public params: any;
  public data: any;
  constructor(
    private modalService: ModalServiceService
  ) { }

  ngOnInit() {
  }

  agInit(params: any): void {
    this.params = params;
    this.data = params && params.api.getDisplayedRowAtIndex(params.rowIndex).data;
  }
  refresh(): boolean {
    return false;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

}
