import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, OnChanges, ComponentFactoryResolver, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ModalServiceService } from './modal-service.service';

import { ModalAddItem } from './modal-addItem';
import { ModalDirective } from './modal-directive';

@Component({
  selector: 'app-side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.scss']
})
export class SideModalComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() title;
  @Input() subTitle;
  @Input() subTitleValue;
  @Input() subTitle1;
  @Input() subTitle1Value;
  @Input() id: string;
  @Input() closeBtn;
  @Input() saveBtn;
  @Input() nextBtn;
  @Input() componentName;
  @Input() selectedData;
  @Input() selectedRowsData;
  @Input() bg;

  @Output() savePopupData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ModalDirective, { static: true }) modalBody: ModalDirective;
  @ViewChild('sidePopup', { static: true }) sidePopup;

  private element: any;

  private tempSelectedDate: any;
  private tempSelectedRowsData: any;

  constructor(
    private modalService: ModalServiceService,
    private el: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;

    // ensure id attribute exists
    if (!this.id) {
        console.error('modal must have an id');
        return;
    }
    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);     
  }

  ngAfterViewInit() {
   
  }

  ngOnChanges() {
    this.tempSelectedDate = Object.assign({}, this.selectedData);
    let rowsSeelcted = [];
    this.selectedRowsData && this.selectedRowsData.forEach((data) => {
      // const row = Object.assign({}, data.data);
      rowsSeelcted.push(data.data);
    });
    this.tempSelectedRowsData = Array.apply([], rowsSeelcted);
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
      this.modalService.remove(this.id);
      this.element.remove();
  }

  // open modal
  open(): void {
    this.sidePopup.show();
    this.loadComponent(this.componentName);
  }

  // close modal
  close() {
    this.tempSelectedDate = this.selectedData;
    this.tempSelectedRowsData = this.selectedRowsData;
    this.sidePopup.hide();
  }

  save() {
    this.sidePopup.hide();
    this.savePopupData.emit(this.selectedRowsData && this.selectedRowsData.length ? this.tempSelectedRowsData : this.tempSelectedDate);

  }
  doFormValidations() {
    if (this.tempSelectedDate) {
      for (const k in this.tempSelectedDate) {
        const chargerName = this.tempSelectedDate['chargerName'];
        if(chargerName) {
          return false;
        } if (!chargerName && this.tempSelectedDate[k] === '') {
          return true;
        }
      }
    }
  }
  loadComponent(componentName) {
    const adItem = this.modalService.getComponent(componentName);
    if (adItem) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(adItem.component);
      const viewContainerRef = this.modalBody.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      (<ModalAddItem>componentRef.instance).data =
        this.selectedRowsData && this.selectedRowsData.length ? this.tempSelectedRowsData : this.tempSelectedDate;
    }
  }
}
