import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/post-login/dashboard.service';

@Component({
  selector: 'app-charge-points-summary-chart',
  templateUrl: './charge-points-summary-chart.component.html',
  styleUrls: ['./charge-points-summary-chart.component.scss']
})
export class ChargePointsSummaryChartComponent implements OnInit {

  @Input() loggedInUser;

  public data: any[] = [{
    kind: 'Online', share: 20, color: '#62A644'
  }, {
    kind: 'Offline', share: 15, color: '#F9A11B'
  }, {
    kind: 'Error', share: 40, color: '#D91E3E'
    }];

  private chargesSummay;

  public chartType = 'doughnut';

  public chartDatasets: number[];

  public chartLabels: Array<any> = ['Online', 'Offline', 'Error'];

  public chartColors: Array<any> = [
    {
      backgroundColor: ['#62A644', 'darkgrey', '#D91E3E'],
      borderWidth: [10, 10, 10],
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  constructor(
    private dashboardSevice: DashboardService
  ) {
    this.dashboardSevice.getChargersData('chargers/summary').subscribe((summary: any) => {
      this.chargesSummay = summary.chargerSummary;
      const chargers: any = [];
      chargers.push(this.chargesSummay.online || this.chargesSummay.Idle || this.chargesSummay.Charging || 0,
        this.chargesSummay.offline || this.chargesSummay.Unavailable || 0, this.chargesSummay.error || this.chargesSummay.Faulted || this.chargesSummay['Multiplexer Faulted'] || 0);
      this.chartDatasets = [chargers, [0, 0, 0]]
      ;
      // this.chartDatasets[0].data = chargers;
    });
  }

  ngOnInit() {
  }


  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

}
