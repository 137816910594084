import { Component, OnInit } from '@angular/core';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public userDetails;

  constructor(
    private loginService: LoginServiceService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.userDetails = this.loginService.getLoggedInUserDetails();
  }

  logout() {
    this.loginService.logout();
    this.authService.logout();
  }

}
