import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DashboardService } from 'src/app/post-login/dashboard.service';

@Component({
  selector: 'app-add-user-popup-body',
  templateUrl: './add-user-popup-body.component.html',
  styleUrls: ['./add-user-popup-body.component.scss']
})
export class AddUserPopupBodyComponent implements OnInit {

  @Input() data;
  public company: any;
  public roles: any;
  public disableRole: Boolean = false;
  public disableUserName: Boolean= false;

  constructor(
    private dashboardService: DashboardService,
  ) {
  }

  ngOnInit() {
     if(this.data.actions && this.data.actions[0] && this.data.actions[0].name){
       if(this.data.actions[0].name === "Edit User"){
         this.disableUserName=true;
        }
      }
    
    this.roles = this.dashboardService.getUserRoles();
    this.company = this.dashboardService.getCompanies();
    if (!this.roles) {
      this.dashboardService.getAllRoles('rolenames').subscribe((res: any) => {
        this.dashboardService.setUserRoles(res.roleNames);
        this.roles = res.roleNames;
      });
    }
    if (!this.company) {
      this.dashboardService.getAllCompanies('companies').subscribe((res: any) => {
        this.dashboardService.setCompanies(res.companies);
        this.company = res.companies;
      });
    }
    this.data = this.data ? this.data : {};
    if (this.data) {
      let count = 0;
      for (const k in this.data) {
        if (this.data[k] !== '') {
          count += 1;
        }
      }
      if (count > 1) {
        this.disableRole = true;
      }
    }
  }
}
