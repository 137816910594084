import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { ChargePointsComponent } from './components/charge-points/charge-points.component';
import { RulesComponent } from './components/rules/rules.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { SharedModule } from './shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
// tslint:disable-next-line: max-line-length
import { ChargePointsSummaryChartComponent } from './components/dashboard/charge-points-summary-chart/charge-points-summary-chart.component';
import { DashboardMainComponent } from './components/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { AgGridModule } from 'ag-grid-angular';
import { PopoverModule, CheckboxModule, CollapseModule, InputsModule, InputUtilitiesModule } from 'angular-bootstrap-md';
import { DashboardService } from './dashboard.service';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ResetPasswordModalTemplateComponent } from './components/user-profile/reset-password-modal-template/reset-password-modal-template.component';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { AddUserPopupBodyComponent } from './components/user-management/add-user-popup-body/add-user-popup-body.component';
import { AssignRulesPopupComponent } from './components/charge-points/assign-rules-popup/assign-rules-popup.component';
import { AddRulesPopupComponent } from './components/rules/add-rules-popup/add-rules-popup.component';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardMainComponent,
    ChargePointsComponent,
    RulesComponent,
    UserManagementComponent,
    ChargePointsSummaryChartComponent,
    UserProfileComponent,
    ResetPasswordModalTemplateComponent,
    AddUserPopupBodyComponent,
    AssignRulesPopupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    DashboardRoutingModule,
    AgGridModule.withComponents([]),
    ChartsModule,
    PopoverModule,
    CheckboxModule,
    CollapseModule,
    InputsModule,
    InputUtilitiesModule,
    ShowHidePasswordModule,
    NgbModule
  ],
  providers: [DashboardService]
})
export class DashboardModule { }
