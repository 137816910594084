import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { LoginServiceService } from '../pre-login/login/login.service';

@Injectable()
export class DashboardService {

  private userRoles: any;
  private companies: any;
  private productInfo: any;

  constructor(
    private httpService: HttpService,
    private loginService: LoginServiceService
  ) { }

  // get productInfo on login
  public getProdInfo(url) {
    return this.httpService.getAPI(url);
  }

  public setProductInfo(data) {
    this.productInfo = data;
  }

  public returnProductInfo() {
    return this.productInfo;
  }

  // get all chargers data/recent chargers data based on url
  public getChargersData(url) {
    return this.httpService.getAPI(url);
  }

  // get log history for rule/charger based on ruleId/chargerId
  public getHistory(url, id) {
    return this.httpService.getAPI(url + '/' + id);
  }

  // prepare action columns for chargers under dashboard/chargers
  public prepareActionsForChargers(array, userRole?) {
    array.forEach((element, index) => {
      element.actions = [];
      if (userRole === 'Admin') {
        element.actions.push(
          {
            name: element.ruleName ? 'Change Rule' : 'Assign Rule',
            modalId: 'assignRulePopup',
            title: element.chargerName ? element.chargerName : 'Charger ' + (index + 1),
            subTitle: 'Charger ID',
            subTitleValue: element.chargerId,
            subTitle1: 'Current Rule',
            subTitle1Value: element.ruleName,
            componentName: 'AssignRulesPopupComponent',
            selectedData: element,
            closeBtn: 'Cancel',
            saveBtn: 'Update',
            for: 'chargers',
            listImage: element.ruleName ? 'change cp.svg' : 'assign-gray.svg'
          });
      }
    element.actions.push(
        {
          name: 'Log History',
          modalId: 'logHistoryPopup',
          title: 'Log History',
          subTitle: 'Charger Name',
          subTitleValue: element.chargerName ? element.chargerName : 'Charger ' + (index + 1),
          componentName: 'LogHistoryModalComponent',
          selectedData: element,
          closeBtn: 'Close',
          for: 'chargers',
          listImage: 'log history.svg'
        }
      );
    });
  }

  // create column actions for rules
  public prepareActionsForRules(array, userRole?) {
    array.forEach((element, index) => {
      element.actions = [];
      if (userRole === 'Admin') {
        element.actions.push({
          name: 'Edit Rule',
          modalId: 'editRulePopup',
          title: 'Edit Rule',
          componentName: 'AddRulesPopupComponent',
          selectedData: element,
          closeBtn: 'Cancel',
          saveBtn: 'Update',
          nextBtn: 'Next',
          for: 'rules',
          listImage: 'edit.svg'
        });
      }
        element.actions.push(
          {
            name: 'View Rule',
            modalId: 'viewRulePopup',
            title: 'View Rule',
            componentName: 'AddRulesPopupComponent',
            selectedData: element,
            closeBtn: 'Close',
            for: 'rules'
          },
          {
            name: 'Log History',
            modalId: 'logHistoryPopup',
            title: 'Log History',
            subTitle: 'Rule Name',
            subTitleValue: element.ruleName,
            componentName: 'LogHistoryModalComponent',
            selectedData: element,
            closeBtn: 'Close',
            for: 'rules',
            listImage: 'log history.svg'
          }
        );
    });
  }

  public prepareActionsForUsers(array) {
    array && array.forEach((element, index) => {
      element.actions = [];
      element.actions.push(
        {
          name: 'Edit User',
          modalId: 'editUserPopup',
          title: 'Edit User',
          componentName: 'AddUserPopupBodyComponent',
          selectedData: element,
          closeBtn: 'Close',
          saveBtn: 'Update',
          listImage: 'edit.svg'
        }
      );
    });
  }

  // update rule for single/multiple charges
  public updateRuleForCharger(url, data) {
    return this.httpService.putAPI(url, data);
  }

  // get all rules/recent rules
  public getRulesData(url) {
    return this.httpService.getAPI(url);
  }

  // delete single rule
  public deleteSingleRule(url, id) {
    return this.httpService.deleteAPI(url + '/' + id);
  }

  // delete multiple rules
  public deleteMultipleRules(url, data) {
    return this.httpService.putAPI(url, data);
  }

  // updating a rule
  public updateRule(url, data) {
    return this.httpService.putAPI(url, data);
  }

  // adding new rule
  public addNewRule(url, data) {
    return this.httpService.postAPI(url, data);
  }

  // get the master data for the rule
  public getMasterDataForRules(url) {
    return this.httpService.getAPI(url);
  }

  // get all users
  public getUsers(url) {
    return this.httpService.getAPI(url);
  }

  // create a new user
  public createNewUser(url, data) {
    return this.httpService.postAPI(url, data);
  }

  // update user
  public updateUser(url, data) {
    return this.httpService.putAPI(url, data);
  }

  // delete single user
  public deleteSingleUser(url, username) {
    return this.httpService.deleteAPI(url + '/' + username);
  }

  // delete multiple users
  public deleteMultipleUsers(url, data) {
    return this.httpService.putAPI(url, data);
  }

  // get roles & use method instead of calling API while creating a user
  public getAllRoles(url) {
    return this.httpService.getAPI(url);
  }

  public setUserRoles(data) {
    this.userRoles = data;
  }

  public getUserRoles() {
    return this.userRoles;
  }

  // get company list
  public getAllCompanies(url) {
    return this.httpService.getAPI(url);
  }

  public setCompanies(data) {
    this.companies = data;
  }

  public getCompanies() {
    return this.companies;
  }
}
