import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/post-login/dashboard.service';

import { _ } from 'underscore';

@Component({
  selector: 'app-add-rules-popup',
  templateUrl: './add-rules-popup.component.html',
  styleUrls: ['./add-rules-popup.component.scss']
})
export class AddRulesPopupComponent implements OnInit {

  @Input() serversList: Array<any> = [];
  @Input() allowList: Array<any> = [];
  @Input() commandList: Array<any> = [];
  @Input() symbolList: Array<any> = [];
  @Input() selectedData: any;
  @Input() textToDisplay;
  @Input() getServerIndex;

  private selectedItem;

  constructor(
    private dashboardService: DashboardService
  ) {
   }

  ngOnInit() {
    this.selectedItem = 0;
  }

  // addFieldValue() {
  //   this.fieldArray.push(this.newAttribute)
  //   this.newAttribute = {};
  // }

  // deleteFieldValue(index) {
  //   this.fieldArray.splice(index, 1);
  // }

  removeRow(e) {
    alert('do u want to remove');
  }

  addCommand(policy) {
    const selectedpolicy = (this.selectedData.servers[0].policies).find((element) => {
      return element.policyName === policy.policyName;
    });
    selectedpolicy.commands.push(
      {
        commandName: '',
        symbol: '',
        value: null
      }
    );
  }

}
