import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewChecked {

  public routerHeight;

  @ViewChild('routerContent', {static: true}) routerContent;
  constructor(
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.authService.isAuthenticated(this);
  }

  ngAfterViewChecked() {
    this.routerHeight = this.routerContent.nativeElement.offsetHeight;
    this.cd.detectChanges();
  }

  getDashboardHeight() {
    return `calc(100vh - 99px)`;
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/login'], { queryParams: { errorMessage: message}});
    }
}
}
