import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pre-login/login/login.component';
import { ForgotPasswordComponent } from './pre-login/forgot-password/forgot-password.component';
import { ForgotPasswordEmailSentComponent } from './pre-login/forgot-password-email-sent/forgot-password-email-sent.component';
import { ResetPasswordComponent } from './pre-login/reset-password/reset-password.component';
import { DashboardModule } from './post-login/dashboard.module';
import { SharedModule } from './post-login/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';

import { AgGridModule } from 'ag-grid-angular';
import {NgxPaginationModule} from 'ngx-pagination';
import { MDBBootstrapModule, WavesModule, CollapseModule } from 'angular-bootstrap-md';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpService } from './http.service';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { LoginServiceService } from './pre-login/login/login.service';
import { ChartsModule } from 'ng2-charts';
import { AuthenticateInterceptorService } from './auth/authenticate-interceptor.service';
import { ErrorInterceptorService } from './auth/error-interceptor.service';
import { AuthService } from './auth/auth.service';
import { CognitoService } from './auth/cognito.service';
import { AWSService } from './auth/aws.service';
import { PasswordValidatorDirective } from './post-login/shared/components/password-validator.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ForgotPasswordEmailSentComponent,
    ResetPasswordComponent,
    PasswordValidatorDirective
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    DashboardModule,
    SharedModule,
    BrowserAnimationsModule,
    AgGridModule.withComponents([]),
    NgxPaginationModule,
    MDBBootstrapModule.forRoot(),
    ChartsModule,
    WavesModule,
    HttpClientModule,
    CollapseModule,
    ShowHidePasswordModule,
    NgbModule
  ],
  providers: [LoginServiceService, HttpService, AuthService, CognitoService, AWSService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticateInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
