import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {DashboardComponent} from './dashboard.component';
import {DashboardMainComponent} from './components/dashboard/dashboard.component';
import {RulesComponent} from '../post-login/components/rules/rules.component';
import {ChargePointsComponent} from '../post-login/components/charge-points/charge-points.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
      },
      {
      path:  'dashboard',
      component:  DashboardMainComponent
      },
      {
      path:  'rules',
      component:  RulesComponent
      },
      {
        path:  'chargePoints',
        component:  ChargePointsComponent
      },
      {
        path:  'userManagement',
        component:  UserManagementComponent
      },
      {
        path: 'userProfile',
        component: UserProfileComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
