import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import {ModalServiceService} from '../../shared/components/side-modal/modal-service.service';
import { DashboardService } from '../../dashboard.service';
import { ActionColumnRendererComponent } from '../../shared/components/action-column-renderer/action-column-renderer.component';
import { LoginServiceService } from 'src/app/pre-login/login/login.service';
import { ChargerNameColumnRendererComponent } from '../../shared/components/charger-name-column-renderer/charger-name-column-renderer.component';
import { RuleNameColumnRendererComponent } from '../../shared/components/rule-name-column-renderer/rule-name-column-renderer.component';
import { CustomFilterComponent } from '../../shared/components/custom-filter/custom-filter.component';
import { AddressColumnRendererComponent } from '../../shared/components/address-column-renderer/address-column-renderer.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-charge-points',
  templateUrl: './charge-points.component.html',
  styleUrls: ['./charge-points.component.scss']
})
export class ChargePointsComponent implements OnInit {

  private openRulesPopup: Boolean = false;
  public gridOptions: any;
  public chargePointsColumnDefs;
  public chargePointsRowData;
  private cellStylesObj: any;
  public frameworkComponents: any;
  public loggedInUserRole;
  public chargesSummay;
  private toFilter: Boolean = false;

  successMessage;
  errorMessage;
  staticAlertClosed = true;

  @ViewChild('clickImgTemp', {static: true}) clickImgTemp: ElementRef;

  constructor(
    private modalService: ModalServiceService,
    private dashboardService: DashboardService,
    private loginService: LoginServiceService,
    private currentRoute: ActivatedRoute
  ) {
    this.frameworkComponents = {
      actionColumn: ActionColumnRendererComponent,
      customFilter: CustomFilterComponent,
      addressColumn: AddressColumnRendererComponent
    };
    this.cellStylesObj = {
      borderRight: '1px solid #E5E5E5;',
      color: '#212121',
      'font-family': 'Gotham',
      'font-size': '14px;',
      'font-weight': '300;',
      'line-height': '60px;'
    };

    this.chargePointsColumnDefs = [
      {
        headerName: 'Charger Name',
        field: 'chargerName',
        width: 186,
        filter: 'customFilter',
        filterParams: {
          value: 'chargerName',
          name: 'Charger Names',
          isStatus: true
        },
        menuTabs: ['filterMenuTab'],
        suppressSizeToFit: true,       
        cellStyle: this.cellStylesObj,
        cellRendererFramework: ChargerNameColumnRendererComponent,
        getQuickFilterText: function(params) {
          return params.value;
        },
      },
      {
        headerName: 'Customer Name',
        field: 'customerName',
        width: 170,
        minWidth: 50,
        maxWidth: 190,
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          color: '#212121;',
          'font-family': 'Gotham;',
          'font-size': '14px;',
          'font-weight': '300',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          'line-height': '60px;'
        },
        filter: 'customFilter',
        filterParams: {
          value: 'customerName',
          name: 'Customer Names'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: 'Servers',
        field: 'servers',
        width: 199,
        tooltipField: 'servers',
        tooltipComponentParams: { color: '#ececec' },
        cellStyle: {
          borderRight: '1px solid #E5E5E5;',
          'color': '#109AD6;',
          'font-family': 'Gotham;',
          'font-size': '14px;',
          'font-weight': '300;',
          'line-height': '60px;',
          'textDecoration': 'underline',
          whiteSpace: 'normal',
          wordWrap: 'break-word'
        },

        filter: 'customFilter',
        filterParams: {
          value: 'servers',
          name: 'Servers'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: 'Charger ID',
        field: 'chargerId',
        width: 120,
        cellStyle: this.cellStylesObj,
        filter: 'customFilter',
        filterParams: {
          value: 'chargerId',
          name: 'charger Id'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: 'Rule',
        field: 'ruleName',
        width: 130,
        cellStyle: this.cellStylesObj,
        tooltipField: 'ruleName',
        tooltipComponentParams: { color: '#ececec' },
        cellRendererFramework: RuleNameColumnRendererComponent,
        filter: 'customFilter',
        filterParams: {
          value: 'ruleName',
          name: 'Rule'
        }
      },
      {
        headerName: 'Charger Type',
        field: 'chargerType',
        width: 124,
        cellStyle: this.cellStylesObj,
        filter: 'customFilter',
        filterParams: {
          value: 'chargerType',
          name: 'Charger Type'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: 'Charger Model',
        field: 'chargerModel',
        width: 140,
        cellStyle: this.cellStylesObj,
        filter: 'customFilter',
        filterParams: {
          value: 'chargerModel',
          name: 'Charger Model'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: 'Charger Address',
        field: 'address',
        width: 160,
        cellStyle: this.cellStylesObj,
        cellRenderer: 'addressColumn',
        tooltipField: 'address',
        filter: 'customFilter',
        filterParams: {
          value: 'address',
          name: 'Charger Address'
        },
        getQuickFilterText: () => '',
      },
      {
        headerName: 'Actions',
        field: 'actions',
        maxWidth: 69,
        cellRenderer: 'actionColumn',
        cellRendererParams: {
          value: 'chargerActions'
        },
        cellStyle: {
          textAlign: 'center',
          'line-height': '60px;'
        },
        suppressMenu: true,
        cellClass: 'no-border',
        sortable: false
      },
    ];
   }

  ngOnInit() {
    this.toFilter = this.currentRoute.snapshot.queryParams['filter'] === 'norule' ? true : false;
    this.loggedInUserRole = this.loginService.getLoggedInUserDetails()['custom:userRole'];
    this.dashboardService.getChargersData('chargers/summary').subscribe((summary: any) => {
      this.chargesSummay = summary.chargerSummary;
    });
    if (this.loggedInUserRole === 'Admin') {
      this.chargePointsColumnDefs.unshift(
        {
          headerName: '',
          field: '',
          width: 40,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          cellStyle: {
            borderRight: '1px solid #E5E5E5;'
          },
          suppressMenu: true,
          sortable: false
        }
      );
    }
    this.dashboardService.getChargersData('chargers').subscribe((data: any) => {
      if (this.toFilter) {
        this.chargePointsRowData = data.chargers.filter((charger) => {
          return !charger.ruleId || !charger.ruleName;
        });
      } else {
        this.chargePointsRowData = data.chargers;
      }
      this.chargePointsRowData.forEach((charger) => {
        charger.address = charger.chargerLocation ? charger.chargerLocation.city : "";
      });
      this.dashboardService.prepareActionsForChargers(this.chargePointsRowData, this.loggedInUserRole);
    });
  }

  savePopupData(data) {
    const dataForMultipleChargersUpdate = {
        chargerIds: [],
        ruleId: 0
    };
    const dataToBeSent = Object.assign({}, data);
    if (data.length) {
      data.forEach((element, index) => {
        dataForMultipleChargersUpdate.ruleId = element.ruleId;
        // dataForMultipleChargersUpdate['ruleName'] = element.data.ruleName;
        dataForMultipleChargersUpdate.chargerIds.push(element.chargerId);
      });
    } else {
      delete dataToBeSent.actions;
    }
    this.dashboardService.updateRuleForCharger(data.length ? 'chargers/rule' : 'chargers',
      data.length ? dataForMultipleChargersUpdate : dataToBeSent).subscribe((res: any) => {
        this.successMessage = res.message;
        this.staticAlertClosed = false;
        setTimeout(() => this.staticAlertClosed = true, 10000);
        this.dashboardService.getChargersData('chargers').subscribe((chargers: any) => {
          this.chargePointsRowData = chargers.chargers;
          this.chargePointsRowData.forEach((charger) => {
            charger.address = charger.chargerLocation ? charger.chargerLocation.city : " ";
          });
          this.dashboardService.prepareActionsForChargers(this.chargePointsRowData, this.loggedInUserRole);
      });
    }, (err: any) => {
      this.errorMessage = err.error.message || err.message;
        this.staticAlertClosed = false;
        setTimeout(() => this.staticAlertClosed = true, 10000);      
    });
  }

  alertType() {
    return this.successMessage ? 'success' : 'danger';
  }

  getGirdAPI(gridAPI) {
    this.gridOptions = gridAPI;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  getSelectedRows() {
    if (this.gridOptions) {
      const selectedNodes = this.gridOptions.getSelectedNodes();
      if (selectedNodes.length) {
        return false;
      } else {
        return true;
      }
    }
  }

  getSelectedRowsData() {
    if (this.gridOptions) {
      const selectedNodes = this.gridOptions.getSelectedNodes();
      return selectedNodes;
    }
  }
}
